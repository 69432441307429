<template>
    <div class="zidingyi">
        <span><span class="mark"><span v-if="isTrue">*</span></span>{{title}}</span>
        <input :type="type" @input="updateVal($event.target.value)">
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    props:{
        title:{
            type: String,
            default: () => '',
        },
        isTrue:{
            type: Boolean,
            default: () => true,
        },
        type:{
            type: String,
            default: () => 'text',
        },
            
    },
    methods: {
        updateVal: function(val) {
            this.$emit('input', val);  
          }
    },
}
</script>
<style lang="scss" scoped>
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #3C72CA inset !important; // 记住密码后的input背景颜色
    -webkit-text-fill-color: #000 !important; //  记住密码后的input字体颜色
}
.zidingyi{
    border: 1px solid #fff;
    border-radius: 10px;
    width: 315px;
    height: 39px;
    margin: auto;
    margin-bottom: 15px;
    line-height: 39px;
    position: relative;
    .mark{
        display: inline-block;
        width: 18px;
        color: #FF0000;
        font-size: 14px;
        text-align: right;
    }
    input{
        height: 33px;
        width: 188px;
        position: absolute;
        border: none;
        outline: none;
        background: transparent;
        right: 4px;
        top: 2px;
        color: #000;
    }
}
</style>
